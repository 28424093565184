import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import CircularProgress from '@mui/material/CircularProgress';
import { doc, getDoc } from "firebase/firestore";
import db from 'src/firebase'


export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [isBetaUser, setIsBetaUser] = useState(true);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        const auth = getAuth();
        /*
        if(currentUser != null){
            updateBetaUserState(currentUser.uid);
        }
        */
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                setCurrentUser(user);
                await user.getIdToken(true);
                const decodedToken = await user.getIdTokenResult();
                setCurrentPlan(decodedToken.claims.stripeRole);
                const docRef = doc(db, "beta_users", user.uid);
                const docSnap = await getDoc(docRef);
                setIsBetaUser(docSnap.exists());
                setPending(false);
            } else {
                setCurrentUser(null);
                setPending(false);
            }
        });
    }, []);

    if (pending) {
        return <div className="flex justify-center items-center text-indigo-400 w-screen h-screen dark:bg-gray-800"><div><CircularProgress /></div></div>
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser, currentPlan, isBetaUser, setIsBetaUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};