import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom';
import { AuthContext } from "src/Auth";
import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc } from "firebase/firestore";
import db from 'src/firebase'


const PaidRoute = ({ children }) => {
    const { currentUser, currentPlan, isBetaUser } = useContext(AuthContext);

    if (currentUser != null) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // ...
        if (currentPlan || currentPlan != null || isBetaUser) {
            const apiKey = uuidv4();
            const uid = currentUser.uid;
            setDoc(doc(db, "api_keys", uid), {
                apiKey: apiKey
            });
            const event = new CustomEvent('extensionRoazSignIn', {
                detail: { apiKey, uid }
            });
            document.dispatchEvent(event);

            return children;
        } else {
            return <Navigate to="/plan" replace={true} />
        }
    } else {
        // No user is signed in.
        return <Navigate to="/login" replace={true} />
    }
    //  const accessToken = localStorage.getItem('accessToken');
    //return accessToken ? children : <Navigate to="/login"/>;
}

export default PaidRoute