import React, { createContext, useContext, useState, useEffect } from 'react';

// Dark Mode Context
const DarkModeContext = createContext();

// Context Provider Component
export const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false); // Initial dark mode state

  // Toggle dark mode function
  const toggleDarkMode = () => {
    document.documentElement.classList.toggle('dark');
    setDarkMode(prevMode => !prevMode);
  };

  // Providing state and toggle function through context
  return (
    <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

// Custom hook to use the dark mode context
export const useDarkMode = () => useContext(DarkModeContext);