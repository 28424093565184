import React, { lazy } from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import AccessibleNavigationAnnouncer from 'src/components/AccessibleNavigationAnnouncer';
import PaidRoute from 'src/PaidRoute';
import { AuthProvider } from './Auth';
import { NotificationsProvider } from '@mantine/notifications';
import { DarkModeProvider } from 'src/context/DarkModeContext';
const Pricing = lazy(() => import('src/pages/Pricing'))
const SetupLayout = lazy(() => import('src/pages/Setup/SetupLayout'));
const Layout = lazy(() => import('src/containers/Layout'))
const Login = lazy(() => import('src/pages/Login'))
const CreateAccount = lazy(() => import('src/pages/CreateAccount'))
const ForgotPassword = lazy(() => import('src/pages/ForgotPassword'))
const Dashboard = lazy(() => import('src/pages/Dashboard'))
const DashboardFb = lazy(() => import('src/pages/DashboardFb'))
const DashboardTiktok = lazy(() => import('src/pages/DashboardTiktok'))
const ShopifyStores = lazy(() => import('src/pages/ShopifyStores'))
const Cogs = lazy(() => import('src/pages/Cogs'))
const NoPageFound = lazy(() => import('src/pages/404'))
const PrivacyPolicy = lazy(() => import('src/pages/PrivacyPolicy'))
const TiktokCallback = lazy(() => import('src/components/authorization/TiktokCallback'))
const CapiSetup = lazy(() => import('src/pages/CapiSetup'))
const ThankYou = lazy(() => import('src/pages/ThankYou'))
const Beta = lazy(() => import('src/pages/Beta'))
const CampaignPricing = lazy(() => import('src/pages/CampaignPricing'))

function App() {
  return (
    <>

      <AuthProvider>
        <DarkModeProvider>
          <NotificationsProvider>
            <AccessibleNavigationAnnouncer />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/create-account" element={<CreateAccount />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/plan" element={<Pricing />} />
              <Route path="/beta" element={<Beta />} />
              <Route path="/callback-tiktok" element={<TiktokCallback />} />
              <Route path="/404" element={<NoPageFound />} />
              {/* Place public routes over this */}
              <Route element={<PaidRoute><Layout /></PaidRoute>}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/dashboard-facebook" element={<DashboardFb />} />
                <Route path="/dashboard-tiktok" element={<DashboardTiktok />} />
                <Route path="/shopify-stores" element={<ShopifyStores />} />
                <Route path="/cost-of-goods" element={<Cogs />} />
                <Route path="/store-setup" element={<SetupLayout />} />
                <Route path="/shopify-stores/capi-setup" element={<CapiSetup />} />
                <Route path="/thank-you" element={<ThankYou />} />
              </Route>
              {/*<Route path="/plan" element={<PrivateRoute><Pricing /></PrivateRoute>} />*/}
              <Route path="/setup" element={<PaidRoute><SetupLayout /></PaidRoute>} />

              {/* Redirects */}
              <Route
                path="/"
                element={<Navigate to="/dashboard" replace />}
              />
              <Route
                path="*"
                element={<Navigate to="/404" replace />}
              />
            </Routes>
          </NotificationsProvider>
        </DarkModeProvider>
      </AuthProvider>

    </>
  );
}

export default App;
