import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

function ThemedSuspense() {
  return (
    <div className="w-full h-screen p-6 text-lg font-medium dark:bg-gray-900">
    </div>
  )
}

export default ThemedSuspense
